//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cs from '@/i18n/sangha-help-cs.json';
import en from '@/i18n/sangha-help-en.json';
import es from '@/i18n/sangha-help-es.json';
import it from '@/i18n/sangha-help-it.json';
import ru from '@/i18n/sangha-help-ru.json';
import { locale } from '@/services/i18n.init';
import axios from 'axios';

const messages = {
  en: en,
  es: es,
  it: it,
  ru: ru,
  cs: cs,
};

export default {
  name: 'Help',
  data() {
    return {
      state: 'default',
      pageContent: null,
    };
  },
  components: {},
  methods: {},
  computed: {},
  mounted() {
    let baseurl = this.$router.options.base + 'static/help/';
    let url = baseurl + 'skeleton.htm';
    axios.get(url).then((response) => {
      // replace image tags in response
      let data = response.data.replace(
        /<IMG_([0-9]+)[ ]?\/>/gi,
        '<img src="' + baseurl + locale + '/$1.png"/>'
      );

      // parse translations into document
      const parser = new DOMParser();
      const doc1 = parser.parseFromString(data, 'text/html');
      let nodes = doc1.querySelectorAll('*');
      nodes.forEach((node) => {
        let translationKey = node.attributes.getNamedItem('t');
        if (translationKey) {
          if (typeof messages[locale][translationKey.value] != 'undefined') {
            node.innerHTML = messages[locale][translationKey.value];
          } else {
            console.log('unknown key: ' + translationKey.value);
            node.innerHTML = translationKey.value;
          }
        }
      });
      this.$refs.content.innerHTML = doc1.body.innerHTML;
    });
  },
};
